import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Carousel, Tabs, Typography } from "antd";
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { chunk, compact, find } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { Waypoint } from "react-waypoint";
import styled from "styled-components";

import JumbotronComponent from "@components/Jumbotron";
import Layout from "@components/Layout";
import LocationContext from '@components/LocationContext';
import SEO from "@components/SEO";
import SubHeading from "@components/Subheading";
import { publicIdToLocal } from "@utils/cloudinary";
import { graphql } from "gatsby";

import qrcode from "@img/qrcode.png";

const { TabPane } = Tabs;
const { Paragraph } = Typography;

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

const QrcodeCSS = styled.div`
.qrcode-content{
  display: flex;
  flex-direction: column; 
  align-items: center;
}
.qrcode-wrapper {
  position:relative;
  text-align: center;
}
.qrcode-title {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  white-space: nowrap;
}
`;

export const ProductIntroCSS = styled.div`
  padding-top: 100px;
  padding-bottom: 50px;

  h2 {
    margin-top: 1rem;
  }

  @media (min-width: 1024px) {
    width: 70vw;
  }

  @media (max-width: 1024px) {
    width: 95vw;
  }

  margin: 0 auto;

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: green;
  }

  .ant-tabs-nav .ant-tabs-tab {
    margin: 0 1rem;
  }

  .ant-tabs-tab-btn {
    color: #4a4a4a;

    @media (max-width: 600px) {
      font-size: 0.9rem;
    }
  }

  .ant-tabs-tab {
    color: #4a4a4a;

    @media (max-width: 600px) {
      margin: 0 15px 0 0;
    }
  }

  .ant-tabs-ink-bar {
    background: green;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .ant-tabs-tabpane.ant-tabs-tabpane-active {
    min-height: 200px;

    animation: fadeIn 0.8s forwards;
  }

  .address {
    text-align: center;
    font-size: 1rem;
  }

  .ant-typography {
    width: 80%;
    margin: 0 auto;

    font-size: 1rem;
    text-align: justify;
    text-justify: inter-character;
  }

  .product-display {
    display: flex;
    justify-content: center;
    padding-bottom: 1rem;

    img {
      margin: 0 auto;
    }
  }
`;

export const ProductPageTemplate = ({ t }) => {
  return (
    <LocationContext.Consumer>
      {props => (
        <>
          <JumbotronComponent
            t={t}
            imgUrl={publicIdToLocal["biel/product_jumbotron_j7wbp7"]}
            isJumbotron
          />
          <ProductComponent
            t={t}
            {...props}
          />
          <TechnologyComponent
            t={t}
            {...props}
          />
          <MaterialComponent
            t={t}
            {...props}
          />
        </>
      )}
    </LocationContext.Consumer>
  );
};

ProductPageTemplate.propTypes = {};

const ProductCarouselCSS = styled.div`
  .carousel-wrapper {
    display: grid;
    grid-template-areas: "prev columns after";
    padding-bottom: 1rem;

    @media (min-width: 1024px) {
      grid-template-columns: 15% 70% 15%;
    }

    @media (max-width: 1024px) {
      grid-template-columns: 50px calc(100% - 100px) 50px;
    }

    @media (max-width: 600px) {
      grid-template-columns: 0px 100% 0px;
    }

    div.carousel-navigation-prev,
    div.carousel-navigation-after {
      align-self: center;
      justify-self: center;
    }

    div.carousel-navigation-prev {
      grid-area: prev;

      &:hover {
        cursor: pointer;
      }
    }

    div.carousel-navigation-after {
      grid-area: after;

      &:hover {
        cursor: pointer;
      }
    }

    .anticon {
      &:hover {
        color: green;
      }
    }

    .carousel-content {
      img {
        margin: 0 auto;
      }
    }
  }
`;

const parseParagraph = lines =>
  lines.split("\n").map((line, index) => {
    return (
      <div key={index}>
        {line.split("*").length > 1 ? (
          <ul>
            {compact(line.split("*")).map((item, unique) => (
              <li key={unique}>{item}</li>
            ))}
          </ul>
        ) : (
          line
        )}
      </div>
    );
  });

const QrcodeComponent = ({ t }) => {
  return (
    <QrcodeCSS>
      <div className="qrcode-content" >
        <div className="qrcode-wrapper" >
          <img src={qrcode} alt="" />
          <div className="qrcode-title">
            {t("registrationInstructions")}
          </div>
        </div>
      </div>
    </QrcodeCSS>
  )
}

const PaneComponent = ({ productType, t, imgUrl }) => {
  const carouselRef = useRef();

  const handlePrevClick = () => {
    if (carouselRef && carouselRef.current) {
      carouselRef.current.prev();
    }
  };

  const handleAfterClick = () => {
    if (carouselRef && carouselRef.current) {
      carouselRef.current.next();
    }
  };

  return (
    <>
      <ProductCarouselCSS>
        <div className="carousel-wrapper">
          <div className="carousel-navigation-prev" onClick={handlePrevClick}>
            <LeftOutlined />
          </div>
          <Carousel ref={carouselRef} ref={carouselRef}>
            {Array.from(imgUrl).map((url, index) => {
              return (
                <div className="carousel-content" key={index}>
                  <img src={url} />
                </div>
              );
            })}
          </Carousel>
          <div className="carousel-navigation-after" onClick={handleAfterClick}>
            <RightOutlined />
          </div>
        </div>
      </ProductCarouselCSS>
      <Paragraph>{parseParagraph(t(`${productType}Content`))}</Paragraph>
    </>
  );
};

const productItems = () => [
  {
    productType: "bg",
    imgUrl: [
      publicIdToLocal["biel/bgnew1_ioss82"],
      publicIdToLocal["biel/bgnew2_q67uag"],
    ],
  },
  {
    productType: "cg",
    imgUrl: [
      publicIdToLocal["biel/cg_dcelmq"],
    ]
  },
  {
    productType: "watch",
    imgUrl: [
      publicIdToLocal["biel/watch_glass_htvzeo"],
    ]
  },
  {
    productType: "fingerprint",
    imgUrl: [
      publicIdToLocal["biel/fingerprint_sa2vgb"],
    ]
  },
];

const materialItems = () => [
  {
    productType: "ceramic",
    imgUrl: [
      publicIdToLocal["biel/_DSC2384_ynknxl"],
    ]
  },
  {
    productType: "sapphire",
    imgUrl: [
      publicIdToLocal["biel/_DSC2391_ln6j4f"],
    ]
  },
  {
    productType: "plastic",
    imgUrl: [
      publicIdToLocal["biel/plastic_d3afc8"],
    ]
  },
];

const ProductComponent = ({ t }) => {
  return (
    <ProductIntroCSS id="products">
      <section>
        <SubHeading text={t("products")} />
        <Tabs size="large" centered defaultActiveKey="bg">
          {productItems().map(({ productType, imgUrl }) => (
            <TabPane tab={t(productType)} key={productType}>
              <PaneComponent t={t} productType={productType} imgUrl={imgUrl} />
            </TabPane>
          ))}
        </Tabs>
      </section>
    </ProductIntroCSS>
  );
};

const MaterialCSS = ProductIntroCSS;

const MaterialComponent = ({ t }) => {
  return (
    <MaterialCSS id="materials">
      <section>
        <SubHeading text={t("material")} />
        <Tabs size="large" centered defaultActiveKey="ceramic">
          {materialItems().map(({ productType, imgUrl }) => (
            <TabPane tab={t(productType)} key={productType}>
              <PaneComponent t={t} productType={productType} imgUrl={imgUrl} />
              {productType === 'sapphire' ? <QrcodeComponent t={t} /> : <></>}
            </TabPane>
          ))}
        </Tabs>
      </section>
    </MaterialCSS>
  );
};

const TechnologyCSS = styled.div``;

const SurfaceBannerCSS = styled.div`
  background-color: #c7d8c6;
  padding-top: 2rem;
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 700px;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .surface-fadein-active {
    animation: fadeIn 1.2s forwards;
  }

  div#surface-banner-wrapper {
    opacity: 0;
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 650px;

    @media (min-width: 1024px) {
      width: 70vw;
    }

    @media (max-width: 1024px) {
      padding: 0 2rem;
    }

    @media (max-width: 800px) {
      display: block;
    }

    div#surface-img-wrapper {
      display: flex;
      justify-content: center;
      max-height: 600px;
    }

    #surface-description {
      align-self: center;
      max-width: 50%;

      @media (max-width: 800px) {
        padding-top: 2rem;
        max-width: 100%;
      }
    }

    .ant-typography {
      color: #244a1e;
    }

    .ant-typography.title {
      font-weight: 800;
      text-align: center;
      padding: 1rem 1rem 1rem 1rem;

      @media (max-width: 800px) {
        font-size: 1.4rem;
      }
    }

    .ant-typography.subtitle {
      font-weight: 500;
      margin: auto;
      padding-bottom: 1rem;

      @media (min-width: 1280px) {
        width: 75%;
      }

      @media (max-width: 800px) {
        font-size: 1rem;
      }

      @media (max-width: 600px) {
        font-size: 0.8rem;
      }
    }

    > div {
      align-self: center;
    }

    #surface-img-wrapper {
      margin: 0 auto;

      img {
        align-self: flex-start;
        max-height: 600px;
      }
    }
  }
`;

const FormTechnologyCSS = styled.div`
  background-color: black;
  height: 600px;
  padding: 3rem 0 2rem 0;
  display: flex;
  align-items: center;

  @media (max-width: 800px) {
    height: 800px;
    padding: 2rem 0;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .form-fadein-active {
    animation: fadeIn 1.2s forwards;
  }

  div#form-banner-wrapper {
    opacity: 0;
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    img {
      max-width: 40%;
      margin-right: 2rem;
      max-height: 400px;
    }

    @media (max-width: 800px) {
      img {
        position: relative;
        top: 75px;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      display: block;
      max-height: 900px;
    }

    @media (min-width: 1024px) {
      width: 70vw;
    }

    .ant-typography {
      color: white;
      line-height: 2rem;
    }

    .ant-typography.title {
      font-weight: 800;
      padding: 3rem 3rem 1rem 3rem;
      text-align: center;

      @media (max-width: 800px) {
        padding: 1rem 1rem 1rem 1rem;
        font-size: 1.4rem;
      }
    }

    .ant-typography.subtitle {
      font-weight: 500;
      margin: auto;

      @media (min-width: 1280px) {
        width: 75%;
      }

      @media (max-width: 800px) {
        font-size: 1rem;
      }

      @media (max-width: 600px) {
        font-size: 0.8rem;
      }
    }

    > div {
      align-self: center;
    }
  }
`;

const DecorationBannerCSS = styled.div`
  background-image: ${props => props.backgroundUrl};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 100vw;
  height: 900px;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  // @todo not working for firefox now
  transition: background-image 0.8s ease-in-out;
  -webkit-transition: background-image 0.8s ease-in-out;
  transform: translate3d(0, 0, 0);

  backface-visibility: hidden;
`;

const DecorationTabCSS = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20%;
  height: 900px;

  @media (max-width: 1440px) {
    display: ${props => (props.language === "en" ? "block" : "flex")};

    .ant-typography.title {
      padding-top: ${props => (props.language === "en" ? "3rem" : "auto")};
      height: ${props => (props.language === "en" ? "750px" : "auto")};
    }

    @media (max-width: 1000px) {
      display: block;

      .ant-typography.title {
        padding: 1rem;
        font-size: 1.4rem;
        height: 750px;
      }
    }
  }

  .ant-typography {
    color: white;
    line-height: 2rem;
  }

  .ant-typography.title {
    font-weight: 800;
    padding: 3rem 3rem 1rem 3rem;
    text-align: center;
  }

  .ant-typography.subtitle {
    font-weight: 500;
    margin: auto;

    @media (min-width: 1280px) {
      width: 75%;
    }

    @media (max-width: 800px) {
      font-size: 1rem;
    }

    @media (max-width: 600px) {
      font-size: 0.8rem;
    }
  }
  .ant-typography.title {
    font-weight: 800;
    text-align: center;
  }

  .ant-typography.subtitle {
    font-weight: 500;
    margin: auto;

    @media (min-width: 1280px) {
      width: 75%;
    }
  }

  > div {
    align-self: center;
    flex: 40%;
  }

  .ant-tabs-nav .ant-tabs-tab {
    margin: 0 1rem;
  }

  .ant-tabs-tab-btn {
    color: white;
    font-size: 1.25rem;

    @media (max-width: 600px) {
      font-size: 0.8rem;
    }
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white;
    font-weight: 800;

    @media (max-width: 600px) {
      font-size: 0.8rem;
    }
  }

  .ant-tabs-tab {
    color: white;
    text-align: center;

    @media (max-width: 600px) {
      margin: 0 15px 0 0;
    }
  }

  .ant-tabs-ink-bar {
    background: white;
  }

  .ant-tabs-nav::before {
    border-bottom: 0px;
  }

  .ant-tabs-tabpane {
    font-size: 1.25rem;
    color: white;
    width: 70%;
    margin: 0 auto;
    text-align: center;

    @media (max-width: 600px) {
      font-size: 0.8rem;
    }
  }

  .ant-tabs-tabpane.ant-tabs-tabpane-active {
    font-size: 1.25rem;

    @media (max-width: 600px) {
      font-size: 0.8rem;
    }
  }

  .ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
    padding: 4px;
  }
`;

const decorationConfig = () => [
  {
    productType: "sputtering",
    backgroundUrl: publicIdToLocal["biel/firstps_rmzif4"],
  },
  {
    productType: "offset",
    backgroundUrl: publicIdToLocal["biel/secondps_asblzs"],
  },
  {
    productType: "nano",
    backgroundUrl: publicIdToLocal["biel/thirdps_ajpn4r"],
  },
];

const TechnologyComponent = ({ t }) => {
  const [formFadeIn, setFormFadeIn] = useState("");
  const [surfaceFadeIn, setSurfaceFadeIn] = useState("");
  const randomIndex = getRandomInt(3);

  const { i18n } = useTranslation("business");

  const [currentBackgroundUrl, setCurrentBackgroundUrl] = useState("");
  const randomPic = decorationConfig()[randomIndex];

  useEffect(() => {
    setCurrentBackgroundUrl(`url(${randomPic.backgroundUrl})`);
  }, []);

  return (
    <TechnologyCSS id="technology">
      {/** hacky way to make the image preload */}
      <img
        src={publicIdToLocal["biel/product_jumbotron_j7wbp7"]}
        style={{ display: "none" }}
        alt=""
      />
      <img
        src={publicIdToLocal["biel/firstps_rmzif4"]}
        style={{ display: "none" }}
        alt=""
      />
      <img
        src={publicIdToLocal["biel/secondps_asblzs"]}
        style={{ display: "none" }}
        alt=""
      />
      <img
        src={publicIdToLocal["biel/thirdps_ajpn4r"]}
        style={{ display: "none" }}
        alt=""
      />
      <img
        src={publicIdToLocal["biel/techfirstpic-min_vqpmh0"]}
        style={{ display: "none" }}
        alt=""
      />
      <img
        src={publicIdToLocal["biel/bgnew1_ioss82"]}
        style={{ display: "none" }}
        alt=""
      />
      <img
        src={publicIdToLocal["biel/bgnew2_q67uag"]}
        style={{ display: "none" }}
        alt=""
      />
      <img
        src={publicIdToLocal["biel/fingerprint_sa2vgb"]}
        style={{ display: "none" }}
        alt=""
      />
      <img
        src={publicIdToLocal["biel/watch_glass_htvzeo"]}
        style={{ display: "none" }}
        alt=""
      />
      <img
        src={publicIdToLocal["biel/cg_dcelmq"]}
        style={{ display: "none" }}
        alt=""
      />
      <img
        src={publicIdToLocal["biel/_DSC2391_ln6j4f"]}
        style={{ display: "none" }}
        alt=""
      />
      <img
        src={publicIdToLocal["biel/_DSC2391_ln6j4f"]}
        style={{ display: "none" }}
        alt=""
      />
      <img
        src={publicIdToLocal["biel/plastic_d3afc8"]}
        style={{ display: "none" }}
        alt=""
      />
      <section>
        <Waypoint
          onEnter={() => {
            setFormFadeIn("form-fadein-active");
          }}
          topOffset="60%"
          bottomOffset="60%"
        >
          <FormTechnologyCSS>
            <div id="form-banner-wrapper" className={formFadeIn}>
              <img
                src={publicIdToLocal["biel/techfirstpic-min_vqpmh0"]}
              />
              <div>
                <Paragraph className="title">{t("formTitle")}</Paragraph>
                <Paragraph></Paragraph>
                <Paragraph className="subtitle">
                  <ul>
                    {t("formList")
                      .split("\n")
                      .map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                  </ul>
                </Paragraph>
              </div>
            </div>
          </FormTechnologyCSS>
        </Waypoint>
        <DecorationBannerCSS backgroundUrl={currentBackgroundUrl}>
          <DecorationTabCSS language={i18n.language}>
            <Paragraph className="title">{t("decorationTitle")}</Paragraph>
            <Tabs
              size="large"
              centered
              defaultActiveKey={randomPic.productType}
              direction="bottom"
              onChange={productType => {
                setCurrentBackgroundUrl(
                  `url(${find(decorationConfig(), { productType })
                    .backgroundUrl
                  })`
                );
              }}
            >
              {decorationConfig().map(({ productType }) => (
                <TabPane
                  tab={t(productType)}
                  key={productType}
                  position="bottom"
                >
                  {t(`${productType}Content`)}
                </TabPane>
              ))}
            </Tabs>
          </DecorationTabCSS>
        </DecorationBannerCSS>
        <Waypoint
          onEnter={() => {
            setSurfaceFadeIn("surface-fadein-active");
          }}
          topOffset="60%"
          bottomOffset="60%"
        >
          <SurfaceBannerCSS>
            <div id="surface-banner-wrapper" className={surfaceFadeIn}>
              <Typography id="surface-description">
                <Paragraph className="title">{t("surfaceTitle")}</Paragraph>
                <Paragraph className="subtitle">{t("surfaceMain")}</Paragraph>
                <Paragraph className="subtitle">
                  <div style={{ display: "flex" }}>
                    {chunk(t("surfaceList").split("\n"), 4).map((list, unique) => (
                      <ul style={{ flex: "50%" }} key={unique}>
                        {list.map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    ))}
                  </div>
                </Paragraph>
              </Typography>
              <div id="surface-img-wrapper">
                <img
                  src={publicIdToLocal["biel/glassyv3_uwj1xw"]}
                />
              </div>
            </div>
          </SurfaceBannerCSS>
        </Waypoint>
      </section>
    </TechnologyCSS>
  );
};

const ProductPage = ({ location }) => {
  const { t, i18n } = useTranslation();

  return (
    <Layout location={location} t={t} i18n={i18n}>
      <SEO title={t("title")} description={t("description")} pathname={location.pathname} />
      <LocationContext.Consumer>
        {
          props => <ProductPageTemplate t={t}  {...props} />
        }
      </LocationContext.Consumer>
    </Layout>
  );
};

ProductPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: {ns: {in: ["business","footer"]}, language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default ProductPage;
